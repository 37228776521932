import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/home.vue";
import Login from "../views/login.vue";
Vue.use(VueRouter);

const routes = [
  // {
  //   path: "/test",
  //   name: "home",
  //   component: Home,
  // },
  // {
  //   path: "/",
  //   name: "home",
  //   component: FoodSafety,
  // },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  // {
  //   path: "/safe",
  //   name: "FoodSafety",
  //   component: FoodSafety,
  // },
  // {
  //   path: "/check",
  //   name: "DigitalInspection",
  //   component: DigitalInspection,
  // },
  // {
  //   path: "/canteen",
  //   name: "canteen",
  //   component: () => import("@/views/canteen/index.vue"),
  // },
  // {
  //   path: "/device",
  //   name: "device-view",
  //   component: () => import("@/views/device-view/index.vue"),
  // },

  {
    path: "/",
    name: "splash",
    component: () => import("@/views/mango/splash/index.vue"),
  },

  // 芒果青年
  {
    path: "/first",
    name: "FirstView",
    component: () => import("@/views/mango/first/index.vue"),
  },
  {
    path: "/second",
    name: "SecondView",
    component: () => import("@/views/mango/second/index.vue"),
  },{
    path: "/third",
    name: "ThirdView",
    component: () => import("@/views/mango/third/index.vue"),
  },{
    path: "/fourth",
    name: "FourthView",
    component: () => import("@/views/mango/fourth/index.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
