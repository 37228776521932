/**
 * @file auth.js
 */
import Cookies from 'js-cookie';

const TokenKey = 'Admin-Token-MGQN';

export function getToken() {
    return Cookies.get(TokenKey);
}

export function setToken(token) {
    return Cookies.set(TokenKey, token);
}

export function removeToken() {
    return Cookies.remove(TokenKey);
}
export function removeUserInfo() {
    Cookies.remove('userName');
    return Cookies.remove('phonenumber');
}
